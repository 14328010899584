const pageTransitionOut = {
  delay: 0,
	length: 0.3,
}

const pageTransitionIn = {
  delay: 0.3,
  length: 0.3,
}

export { pageTransitionOut, pageTransitionIn }
