module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-182656378-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":300,"defer":true,"enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"clerestory","accessToken":"MC5ZRmxnRVJJQUFDSUFGamRa.77-977-9ae-_vS1xbQ7vv700A0dtOkPvv73vv71Q77-977-977-977-977-9AiDvv70YZu-_ve-_ve-_ve-_vQ","promptForAccessToken":true,"apiEndpoint":"https://clerestory.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Clerestory","short_name":"Clerestory","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"774a85ea5f7d79f97e5a55525a74ac53"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
