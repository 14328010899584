import { useEffect, useRef } from 'react'

const useEventListener = (
  event,
  handler,
  element = typeof window === `undefined` ? {} : window,
  options = {}
) => {
  // Destructure options
  const { capture, passive, once } = options

  // Create a ref that stores handler
  const savedHandler = useRef((e) => { return })

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
      // Make sure element supports addEventListener
      const isSupported = element && element?.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = (e) => savedHandler.current(e)

      // Add event listener
      element?.addEventListener(event, eventListener, { capture, passive, once })

      // Remove event listener on cleanup
      return () => {
        element?.removeEventListener(event, eventListener, { capture, passive, once })
      }
    },
    [event, element, capture, passive, once] // Re-run if event or element changes
  )
};

export default useEventListener
export { useEventListener }