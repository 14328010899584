/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// require('./src/styles/main.global.scss')
import './src/css/index.css'
import * as React from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import 'gatsby-plugin-prismic-previews/dist/styles.css'

export const onClientEntry = () => {
	smoothscroll.polyfill()
}

export const wrapRootElement = ({ element }) => (
	<PrismicPreviewProvider>{element}</PrismicPreviewProvider>
)