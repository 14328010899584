/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'
import contrast from 'contrast'
import Header from './Header'
import { IssueIndex } from './IssueIndex'
import { Splash } from './Splash'
import scrollIntoView from '../utils/scrollPromise'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import useEventListener from '../hooks/useEventListener'
import Subscribe from './Subscribe'

const LAYOUT_QUERY = graphql`
	query LAYOUT_QUERY {
		allFeedClerestoryPods {
      nodes {
        enclosure {
          type
          url
          length
        }
        guid
        id
        itunes {
          author
          duration
          episode
          season
          summary
          title
          image {
            attrs {
              href
            }
          }
        }
        link
        pubDate
      }
    }
    prismicMetadata {
      _previewable
      data {
        splash_content {
          html
          raw
        }
      }
    }
		allPrismicIssue(
			sort: { order: DESC, fields: data___issue_number___text }
      limit: 1
		) {
      nodes {
        _previewable
        id
        data {
          issue_number {
            text
          }
          color
        }
      }
		}
	}
`

const LocalStateContext = createContext()
const LocalStateProvider = LocalStateContext.Provider

const Layout = ({ children, location }) => {
  const staticData = useStaticQuery(LAYOUT_QUERY),
    { data, isPreview: isPrismicPreview } = useMergePrismicPreviewData(staticData),
    {
      allFeedClerestoryPods: { nodes: podcasts },
      prismicMetadata: { data: { splash_content } },
      allPrismicIssue: { nodes }
    } = data

	const [initialPathname, setInitialPathname] = useState(location.pathname),
    isPreview = /^\/preview/.test(initialPathname),
    [color, setColor] = useState(nodes[0].data.color ?? `#D6E3EE`),
    [issueNumber, setIssueNumber] = useState(parseInt(nodes[0].data.issue_number.text ?? `0`, 10)),
    [previewIssue, setPreviewIssue] = useState(),
    [previewMetadata, setPreviewMetadata] = useState(false),
    [isNotFound, setIsNotFound] = useState(false),
    [footerHeight, setFooterHeight] = useState(0)

  const [referrer, setReferrer] = useState(),
    referrerIsUnknown = typeof referrer !== `string`,
    referrerIsClerestory = !referrerIsUnknown && (
      referrer.includes(`clerestory-magazine.myshopify.com`) ||
      referrer.includes(`clerestorymag.com`)
    ),
    [visible, setVisible] = useState(false),
    showSplash = (!isPreview || previewIssue || previewMetadata) && !referrerIsUnknown && !referrerIsClerestory

  useEffect(() => {
    setReferrer(document.referrer)
    setVisible(true)
  }, [])

  // Lazyloading
  const [issuesLoaded, setIssuesLoaded] = useState(2),
    [requestedIssue, setRequestedIssue] = useState()

	// Episode
	const sortedPodcasts = podcasts.slice(0).sort((a, b) => parseInt(b.itunes.episode, 10) - parseInt(a.itunes.episode, 10)),
    [currentEpisode, setCurrentEpisode] = useState({
      autoplay: false,
      ...sortedPodcasts[0]
    }),
    [playEpisode, setPlayEpisode] = useState(() => {}),
    [nextIndex, setNextIndex] = useState(1),
    [previousIndex, setPreviousIndex] = useState(sortedPodcasts.length - 1),
    [nextEpisode, setNextEpisode] = useState(sortedPodcasts[nextIndex]),
    [previousEpisode, setPreviousEpisode] = useState(sortedPodcasts[previousIndex])

  const [sidebarVisible, setSidebarVisible] = useState(true),
    [sidebarDelayElapsed, setSidebarDelayElapsed] = useState(false)

  useEffect(() => {
    if(visible) setTimeout(() => {
      setSidebarDelayElapsed(true)
    }, 500)
  }, [visible])

  useEffect(() => {
    const currentIndex = sortedPodcasts.findIndex(episode => episode.id === currentEpisode.id),
      newNextIndex = currentIndex + 1 < sortedPodcasts.length ? currentIndex + 1 : 0,
      newPreviousIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : sortedPodcasts.length - 1

    if(nextIndex !== newNextIndex) {
      setNextIndex(newNextIndex)
      setNextEpisode(sortedPodcasts[newNextIndex])
    }

    if(previousIndex !== newPreviousIndex) {
      setPreviousIndex(newPreviousIndex)
      setPreviousEpisode(sortedPodcasts[newPreviousIndex])
    }
  }, [currentEpisode])

  // Reference/footnote handling
  const scrollToReference = (e) => {
    // Short ciruit if keyup is not enter
    if(e.type === `keyup` && e.key !== `Enter`) return
    // Short circuit if click / keypress is not to reference element
    if(!(e.target.matches(`[data-reference],[data-footnote]`))) return
    // Short circuit if href is absent
    if(!e.target.getAttribute(`href`)) return

    // Identify relevant reference element
    const referenceId = e.target.getAttribute(`href`),
      referenceElement = document.querySelector(referenceId)

    // Highlight reference element
    referenceElement.classList.add(`highlight`)

    // Scroll to reference element leaving room for navigation
    scrollIntoView({
      element: referenceElement,
      top: -192,
    }).then(() => {
      // When scroll is complete
      // Focus
      referenceElement.focus()
      // Start timer to remove highlight
      setTimeout(() => {
        referenceElement
          && referenceElement.classList.remove(`highlight`)
      }, 2000)
    }).catch(() => {
      // Failed to scroll to reference
      // Start timer to remove highlight
      setTimeout(() => {
        referenceElement
          && referenceElement.classList.remove(`highlight`)
      }, 2000)
    })
  }

  // Reference/footnote handling
  useEventListener(`click`, scrollToReference)
  useEventListener(`keyup`, scrollToReference)
  
  useEventListener(`scroll`, () => {
      if(document.scrollingElement.scrollHeight - document.documentElement.clientHeight - footerHeight > document.documentElement.scrollTop) {
        if(!sidebarVisible) setSidebarVisible(true)
      } else {
        if(sidebarVisible) setSidebarVisible(false)
      }
    },
    typeof window === `undefined` ? {} : window,
    { passive: true }
  )

  const scrollToIssue =  (uid) => {
    const element = document
      .getElementById(`issue-${uid}`)

    if(element) {
      // Scroll to element if available
      const anchor = element.querySelector(`a`)
      scrollIntoView({ element }).then(() => {
        navigate(`#issue-${uid}`)
        anchor.focus()
      }).catch(console.log)
    } else {
      // Otherwise scroll to bottom of page
      setRequestedIssue(uid)
      scrollIntoView({ top: document.scrollingElement.scrollHeight - document.documentElement.clientHeight }).then(() => {
        // Then scroll to element
        scrollToIssue(uid)
      }).catch(console.log)
    }
  }

	return (
		<LocalStateProvider
			value={{
        color, setColor,
				currentEpisode, setCurrentEpisode,
        footerHeight, setFooterHeight,
        issuesLoaded, setIssuesLoaded,
        isNotFound, setIsNotFound,
        issueNumber, setIssueNumber,
        nextEpisode, previousEpisode,
        playEpisode, setPlayEpisode,
        previewIssue, setPreviewIssue,
        previewMetadata, setPreviewMetadata,
        requestedIssue, setRequestedIssue,
        scrollToIssue,
			}}
		>
			<div className={`antialiased hyphens ${visible ? `opacity-100` : `opacity-0`} transition-opacity duration-300`}>
        <style>{`
          :root {
            --color-issue: ${color};
            --color-on-issue: ${contrast(color) === `light` ? `#000000` : `#FFFFFF`};
          }
        `}</style>
        {showSplash && <Splash issueNumber={issueNumber} splashContent={splash_content}></Splash>}
				<Header />
        <IssueIndex />
				<main id="main" className="relative">
					{children}
				</main>
			</div>
		</LocalStateProvider>
	)
}

export const useLocalState = () => {
  return useContext(LocalStateContext)
}

export default Layout
