const arrayOrOnlyItem = require('./arrayOrOnlyItem')

const replaceNonBreakingSpace = (content, children) => {
  let childrenOrChild = arrayOrOnlyItem(content)

  if(children) {
    childrenOrChild = arrayOrOnlyItem(children)
    if(Array.isArray(childrenOrChild)) return children.map(child => replaceNonBreakingSpace(child))
    return replaceNonBreakingSpace(childrenOrChild)
  }
  
  if(typeof childrenOrChild === `string`) return childrenOrChild.replace(/\u00A0/g, ` `)

  return content
}

module.exports = replaceNonBreakingSpace