import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useRef, useEffect } from "react"
import { useEventListener } from "../hooks/useEventListener"
import { useLocalState } from "./Layout";
import Play from '../assets/svg/play.svg'
import Pause from '../assets/svg/pause.svg'
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink";
import { pageTransitionIn, pageTransitionOut } from "../utils/pageTransition";

const padStart = (increment) => `${increment}`.padStart(2, `0`),
  formatTime = (duration) => {
    const seconds = Math.floor(duration % 60),
      minutes = Math.floor(duration / 60 % 60),
      hours = Math.floor(duration / 60 / 60)

    return `${padStart(hours)}:${padStart(minutes)}:${padStart(seconds)}`
  },
  parseTime = (hhmmss) => {
    return hhmmss.split(`:`).reduce((accumulator, value, index, array) => {
      return accumulator + (value * Math.pow(60, array.length - index - 1))
    }, 0)
  }

const query = graphql`
  query PodcastCoverQuery {
    allFeedClerestoryPodsMeta {
			nodes {
				image {
          url
				}
			}
		}
  }
`
const Player = () => {
  const {
      currentEpisode,
      nextEpisode: next,
      previousEpisode: previous,
      setCurrentEpisode: setEpisode,
      setPlayEpisode,
    } = useLocalState(),
    [artwork, setArtwork] = useState([]),
    { allFeedClerestoryPodsMeta } = useStaticQuery(query),
    audioElement = useRef(null)

  const {
      autoplay,
      enclosure: {
        type,
        url: src,
      },
      itunes: {
        duration: durationString,
        image,
        episode,
        season,
        title
      }
    } = currentEpisode,
    duration = parseTime(durationString),
    imageSrc = image?.attrs?.href
      ?? allFeedClerestoryPodsMeta?.nodes[0]?.image?.url
      ?? ``

	const seekRange = useRef(null),
    insufficientData = !(duration && src && title && type),
    [loading, setLoading] = useState(false),
    [playing, setPlaying] = useState(false),
    [currentTime, setCurrentTime] = useState(0),
    [formattedTime, setFormattedTime] = useState(formatTime(currentTime)),
    [formattedTimeRemaining, setFormattedTimeRemaining] = useState(formatTime(Math.ceil(duration - currentTime)))

  const seek = (e) => {
      if(!audioElement.current) return
      audioElement.current.currentTime = parseInt((e.target).value, 10)
      const newCurrentTime = parseInt((e.target).value, 10)
      setCurrentTime(newCurrentTime)
      setFormattedTime(formatTime(newCurrentTime))
      setFormattedTimeRemaining(formatTime(Math.ceil(duration - newCurrentTime)))
    },
    play = () => {
      if(!audioElement.current) return
      if(audioElement.current.paused) audioElement.current.play().catch((error) => console.log(error))
    },
    pause = () => {
      if(!audioElement.current) return
      if(!audioElement.current.paused) audioElement.current.pause()
    },
    skip = (forward = true, increment = 15) => {
      if(!audioElement.current) return
      const newTime = audioElement.current.currentTime + (forward ? 1 : -1) * increment,
        newConstrainedTime = Math.min(duration, Math.max(0, newTime))
      audioElement.current.currentTime = newConstrainedTime
    }

  const changeEpisode = (episode) => {
    if(!episode) return
    // Pause current media
    pause()
    setEpisode({
      autoplay: true,
      ...episode
    })
  }

  // Pass play function up to layout
  useEffect(() => {
    setPlayEpisode(() => play)
  }, [])

  useEffect(() => {
    setArtwork([
      {
        src: imageSrc,
        sizes: `3000x3000`,
        type: `type/jpeg`,
      }
    ])
  }, [imageSrc])

  useEventListener(
    `waiting`,
    () => setLoading(true),
    audioElement.current
  )

  useEventListener(
    `canplay`,
    () => setLoading(false),
    audioElement.current
  )

  // Update range and timestamps throughout playback
  useEventListener(
    `timeupdate`,
    () => {
      if(!seekRange.current || !audioElement.current) return
      seekRange.current.value = `${audioElement.current.currentTime}`
      const newCurrentTime = audioElement.current.currentTime
      setCurrentTime(newCurrentTime)
      setFormattedTime(formatTime(newCurrentTime))
      setFormattedTimeRemaining(formatTime(Math.ceil(duration - newCurrentTime)))
    },
    audioElement.current
  )

  // Update Media Session when user seeks
  useEventListener(
    `seeked`,
    () => {
      if(!audioElement.current) return
      const newCurrentTime = audioElement.current.currentTime

      if(`mediaSession` in navigator && navigator.mediaSession) {
        if (`setPositionState` in navigator.mediaSession && navigator.mediaSession.setPositionState) {
          navigator.mediaSession.setPositionState({
            duration: duration,
            playbackRate: 1,
            position: newCurrentTime,
          })
        }
      }
    },
    audioElement.current
  )

  useEventListener(
    `ended`,
    () => setPlaying(false),
    audioElement.current
  )

  useEventListener(
    `pause`,
    () => setPlaying(false),
    audioElement.current
  )

  useEventListener(
    `play`,
    () => setPlaying(true),
    audioElement.current
  )

  // Update position state when duration changes
  useEffect(() => {
    setFormattedTimeRemaining(formatTime(Math.ceil(duration - currentTime)))

    if(`mediaSession` in navigator && navigator.mediaSession) {
      // Reset current time
      if (`setPositionState` in navigator.mediaSession && navigator.mediaSession.setPositionState) {
        navigator.mediaSession.setPositionState({
          duration: duration,
          playbackRate: 1,
          position: 0,
        })
      }
    }
  }, [duration])

  // Update player actions when next/previous change
  useEffect(() => {
    if(`mediaSession` in navigator && navigator.mediaSession) {
      // Update player when actions occur via Media Session API
      try {
        navigator.mediaSession.setActionHandler(`previoustrack`, () => changeEpisode(previous))
        navigator.mediaSession.setActionHandler(`nexttrack`, () => changeEpisode(next))
        navigator.mediaSession.setActionHandler(`seekforward`, () => skip(true))
        navigator.mediaSession.setActionHandler(`seekbackward`, () => skip(false))
        navigator.mediaSession.setActionHandler(`seekto`, ({ action, fastSeek, seekTime }) => {
          if(!audioElement.current) return
          audioElement.current.currentTime = Math.min(duration, seekTime)
        })
      } catch(error) {
        console.log(error)
      }
    }
  }, [next, previous])

  // Load new media when src changes
  useEffect(() => {
    pause()
    audioElement.current?.load()
    if(autoplay) {
      play()
    } else {
      // Update play state because pause event may not fire
      setPlaying(false)
    }
  }, [src])

  // Update artwork
  useEffect(() => {
    if(artwork.length) {
      if(`mediaSession` in navigator && navigator.mediaSession) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title,
          artist: `The Clerestory Podcast`,
          artwork,
        })
      }
    }
  }, [artwork, title])

	return (
    <div className="fixed z-50 w-full top-0 right-0 lg:max-w-xs p-5 pt-4 bg-gray lg:bg-transparent bg-opacity-50 lg:bg-opacity-0 text-right">
      <div>
        <h5 className="inline lg:py-0.5 text-xs uppercase">
          <TransitionLink
            to="/podcast"
            entry={pageTransitionIn}
            exit={pageTransitionOut}
          >
            The Clerestory Podcast S{season} E{episode}
          </TransitionLink>
        </h5>
        <br className="lg:hidden" />
      </div>
      <div className="max-w-full truncate text-xs leading-6 pt-px">
        <span className="lg:py-0.5 text-match italic">
          {title}
        </span>
      </div>
      <div>
        <div className="flex items-center justify-end">
          <div className="mr-2" style={{ marginTop: `0px` }}>
            <button onClick={() => playing ? pause() : play()}>
              {playing
                ? <Pause className="w-2.5 h-2.5" />
                : <Play className="w-2.5 h-2.5" />
              }
            </button>
          </div>
          <input
            className="inline-block max-w-xxs h-4 appearance-none bg-transparent border-black rounded-none"
            value={currentTime}
            max={duration}
            min={0}
            onChange={seek}
            ref={seekRange}
            step="any"
            type="range"
          />
        </div>
        <audio ref={audioElement} preload="metadata">
          <source src={src} type={type} />
        </audio>
        <div className="-mt-2 font-sans text-xxs">
          <span className={insufficientData || loading ? `` : `opacity-0`}>Loading... </span>
          <span>
            <button className="hover:text-issue focus-visible:text-issue transition-colors duration-300" onClick={() => skip(false)}>(–15)</button>{` `}
            {formattedTime} / {formattedTimeRemaining}{` `}
            <button className="hover:text-issue focus-visible:text-issue transition-colors duration-300" onClick={() => skip(true)}>(+15)</button>
          </span>
        </div>
      </div>
    </div>
	)
}

export default Player
export { Player }