import React from 'react'
import { Helmet } from 'react-helmet'
import { useEventCondition } from '../hooks/useEventCondition'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../utils/htmlSerializer'

const Splash = ({ issueNumber, splashContent }) => {
  const splashDismissed = useEventCondition({
      event: `click`,
      handler: () => true
    })

	return (
		<div
			className={`fixed z-100 inset-0 w-full h-full flex items-center justify-center bg-issue transform ${!splashDismissed ? `` : `opacity-0 pointer-events-none`} transition-opacity duration-500 ease-in-out`}
		>
      <Helmet>
        <body className={!splashDismissed ? `overflow-hidden` : ``} />
      </Helmet>
			<div
				className={`w-full p-5 pt-4 text-center text-on-issue opacity ${
					!splashDismissed ? `opacity-100` : `opacity-0`
				} transition-opacity duration-300`}
			>
				<h1 className="mb-4 font-brand text-4xl lg:text-6xl uppercase">Clerestory</h1>
				{splashContent?.html && (
          <div
            className="w-full max-w-md lg:max-w-lg mx-auto text-xl italic"
          >
            <RichText
              htmlSerializer={htmlSerializer}
              render={splashContent?.raw}
            />
          </div>
        )}
			</div>
		</div>
	)
}

export default Splash
export { Splash }