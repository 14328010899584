import { useState } from "react"
import { useEventListener } from "./useEventListener"

const useEventCondition = ({
  event,
  handler,
  element = typeof window === `undefined` ? {} : window,
  options,
  initialValue = false,
}) => {
  const [value, setValue] = useState(initialValue)

  // Wrap user-provided event handler with a function that stores a boolean of the result
  const wrapper = (e) => {
    const eventHandlerValue = Boolean(handler(e))
    if(eventHandlerValue !== value) setValue(eventHandlerValue)
  }

  useEventListener(event, wrapper, element, options)

  return value
}

export default useEventCondition
export { useEventCondition }