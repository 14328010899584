import React, { useState, useRef } from 'react'
import { Player } from './Player'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import useEventListener from '../hooks/useEventListener'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false),
		toggleNav = () => setIsMenuOpen(!isMenuOpen),
		closeNav = () => setIsMenuOpen(false),
    [pinned, setPinned] = useState(true),
    scrollTop = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    lastPinned = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    linkClasses = `${isMenuOpen ? `pointer-events-auto` : `pointer-events-none`} ${pinned ? `lg:pointer-events-auto` : `lg:pointer-events-none`} inline-block w-max mx-1.5 lg:mx-3 font-sans text-on-issue hover:text-on-issue focus-visible:text-on-issue text-lg leading-tight lg:py-0.5 lg:uppercase lg:text-black lg:hover:text-issue lg:focus-visible:text-issue lg:text-xs`,
		linkProps = {
			className: linkClasses,
			activeClassName: `lg:font-bold`,
			onClick: () => closeNav(),
			entry: pageTransitionIn,
			exit: pageTransitionOut,
      style: {
        transitionProperty: `opacity, color`,
      },
		}

    useEventListener(
      `scroll`,
      (e) => {        
        const newScrollTop = document.scrollingElement.scrollTop,
          scrollChange = newScrollTop - (scrollTop.current ?? 0)

        // Update last pin reference whenever scollChange is < 0
        if (scrollChange < 0) lastPinned.current = newScrollTop

        // If newScrollTop is > 100 from last pin reference, unpin the navigation
        if (newScrollTop - lastPinned.current > 100 && pinned) setPinned(false)

        // If scrollChange is < 0, pin the navigation
        if (scrollChange < 0 && !pinned) setPinned(true)

        // Store newScrollTop
        scrollTop.current = newScrollTop ?? scrollTop.current
      },
      typeof window === `undefined` ? {} : window,
      { passive: true }
    )

	return (
		<>
			<nav className="header p-5 py-4 lg:pt-5 sticky top-0 z-90 flex lg:hidden">
				<div>
					<button
						className="-ml-5 -mt-4 p-5 py-4 uppercase font-sans text-xs z-90 outline-none border-none"
						onClick={() => toggleNav()}
					>
						{!isMenuOpen ? (
							<span>Menu</span>
						) : (
							<span className="font-bold text-on-issue hover:text-on-issue focus-visible:text-on-issue">Close</span>
						)}
					</button>
				</div>
			</nav>
			<nav
				className={`${isMenuOpen ? `pointer-events-auto` : `pointer-events-none`} ${pinned ? `` : `lg:opacity-0`} transition-opacity duration-300 lg:pointer-events-none fixed top-0 block w-full h-full z-80 lg:h-auto lg:py-4 lg:z-50`}
			>
				<div
					className={`relative nav-inner flex flex-col w-full h-full p-10 pt-16 transform ${
						isMenuOpen ? `opacity-100` : `opacity-0`
					} transition-opacity duration-300 backdrop-filter backdrop-blur lg:backdrop-filter-none lg:items-center lg:opacity-100 lg:transition-none lg:bg-transparent lg:h-auto lg:p-0`}
				>
					<div className="lg:hidden absolute -z-10 inset-0 bg-issue opacity-90" />
          <div className="flex flex-col h-full items-center justify-center">
            <div className="lg:order-2 inline-flex w-full">
              <div className="w-1/2 lg:hidden inline-flex items-start justify-end">
                <TransitionLink to="/" className={`${isMenuOpen ? `pointer-events-auto` : `pointer-events-none`} mx-1.5 font-sans font-bold text-on-issue hover:text-on-issue focus-visible:text-on-issue text-lg leading-tight`} entry={linkProps.entry} exit={linkProps.exit} onClick={linkProps.onClick}>
                  Archive
                </TransitionLink>
              </div>
              <div className="w-1/2 lg:w-auto inline-flex flex-col lg:block">
                <TransitionLink to="/" {...linkProps}>
                  All
                </TransitionLink>
                <TransitionLink to="/essays" {...linkProps}>
                  Essays
                </TransitionLink>
                <TransitionLink to="/interviews" {...linkProps}>
                  Interviews
                </TransitionLink>
                <TransitionLink to="/photo-stories" {...linkProps}>
                  Photo Stories
                </TransitionLink>
                <TransitionLink to="/poems" {...linkProps}>
                  Poems
                </TransitionLink>
              </div>
            </div>
            <div className="lg:order-1 inline-flex w-full mt-6 lg:mt-0">
              <div className="w-1/2 lg:hidden inline-flex items-start justify-end">
                <TransitionLink to="/about" className={`${isMenuOpen ? `pointer-events-auto` : `pointer-events-none`} mx-1.5 font-sans font-bold text-on-issue hover:text-on-issue focus-visible:text-on-issue text-lg leading-tight`} entry={linkProps.entry} exit={linkProps.exit} onClick={linkProps.onClick}>
                  Information
                </TransitionLink>
              </div>
              <div className="w-1/2 lg:w-auto inline-flex flex-col lg:block lg:mx-auto">
                <TransitionLink to="/about" {...linkProps}>
                  About
                </TransitionLink>
                <TransitionLink to="/print" {...linkProps}>
                  Print
                </TransitionLink>
                <TransitionLink to="/podcast" {...linkProps}>
                  Podcast
                </TransitionLink>
                <TransitionLink to="/submissions" {...linkProps}>
                  Submissions
                </TransitionLink>
              </div>
            </div>
          </div>
				</div>
			</nav>
      <Player />
		</>
	)
}

export default Header
