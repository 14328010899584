const React = require('react')
const prismicReactJS = require('prismic-reactjs')
const replaceNonBreakingSpace = require('./replaceNonBreakingSpace')
const propsWithUniqueKey = require('./propsWithUniqueKey')
const arrayOrOnlyItem = require('./arrayOrOnlyItem')
const Elements = prismicReactJS.Elements

let referenceCount = 0,
  footnoteCount = 0

const htmlSerializer = (type, element, content, children, key) => {
  const childrenOrChild = arrayOrOnlyItem(children)

  // Return HTML for an piece of content.
  switch(type) {
    case Elements.paragraph:
      // Null
      if(childrenOrChild === null || /^\s*$/.test(childrenOrChild)) return React.createElement(React.Fragment, propsWithUniqueKey({}, key))

      // Null child
      if(
        Array.isArray(children)
          && children.length === 1
          && children[0].props
          && children[0].props.children
          && /^\s*$/.test(children[0].props.children)
      ) return React.createElement(React.Fragment, propsWithUniqueKey({}, key))

      // Only asterisk
      if(/^\*$/.test(childrenOrChild)) return React.createElement(`p`, propsWithUniqueKey({ className: `text-center` }, key), `*`)

      // Only footnote
      if(
        Array.isArray(children)
          && children.length === 1
          && children[0].props
          && children[0].props[`data-footnote`]
      ) return React.createElement(`p`, propsWithUniqueKey({ "data-only-footnote": true }, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))

      // Default
      return React.createElement(`p`, propsWithUniqueKey({}, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))

    case Elements.heading2:
      return React.createElement(`h2`, propsWithUniqueKey({}, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))

    case Elements.heading4:
      return React.createElement(`h4`, propsWithUniqueKey({}, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))

    case Elements.label:
      switch(element.data.label) {
        case `reference`:
          referenceCount++
          return React.createElement(`span`, propsWithUniqueKey({
            "data-reference": true,
            id: `reference-${referenceCount}`,
            href: `#footnote-${referenceCount}`,
            tabIndex: 0,
          }, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))
        case `footnote`:
          footnoteCount++
          return React.createElement(`span`, propsWithUniqueKey({
            "data-footnote": true,
            id: `footnote-${footnoteCount}`,
            href: `#reference-${footnoteCount}`,
            tabIndex: 0,
          }, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))
        default:
          return React.createElement(`span`, propsWithUniqueKey({
            className: element.data.label
          }, key), arrayOrOnlyItem(replaceNonBreakingSpace(content, children)))
      }

    case Elements.strong:
      return React.createElement(`strong`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))

    case Elements.em:
      return React.createElement(`em`, propsWithUniqueKey({}, key), replaceNonBreakingSpace(content, children))
  }
  return null
}

module.exports = htmlSerializer