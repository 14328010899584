// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-essays-js": () => import("./../../../src/pages/essays.js" /* webpackChunkName: "component---src-pages-essays-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-photo-stories-js": () => import("./../../../src/pages/photo-stories.js" /* webpackChunkName: "component---src-pages-photo-stories-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-poems-js": () => import("./../../../src/pages/poems.js" /* webpackChunkName: "component---src-pages-poems-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/AuthorPage.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-essay-page-js": () => import("./../../../src/templates/EssayPage.js" /* webpackChunkName: "component---src-templates-essay-page-js" */),
  "component---src-templates-interview-page-js": () => import("./../../../src/templates/InterviewPage.js" /* webpackChunkName: "component---src-templates-interview-page-js" */),
  "component---src-templates-issue-page-js": () => import("./../../../src/templates/IssuePage.js" /* webpackChunkName: "component---src-templates-issue-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-photo-story-page-js": () => import("./../../../src/templates/PhotoStoryPage.js" /* webpackChunkName: "component---src-templates-photo-story-page-js" */),
  "component---src-templates-poem-page-js": () => import("./../../../src/templates/PoemPage.js" /* webpackChunkName: "component---src-templates-poem-page-js" */)
}

