import React, { useLayoutEffect, useRef, useState } from 'react'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocalState } from '../components/Layout'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Arch } from '../components/Logo'
import linkResolver from '../utils/linkResolver'
import useEventListener from '../hooks/useEventListener'
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'

const ISSUE_INDEX_QUERY = graphql`
	query ISSUE_INDEX_QUERY {
		allPrismicIssue(
			sort: { order: DESC, fields: data___issue_number___text }
		) {
			nodes {
        _previewable
				id
				uid
        type
				data {
					issue_number {
						text
					}
					issue_title {
						text
					}
					featured_image {
            alt
            gatsbyImageData
					}
				}
			}
		}
	}
`

const IssueIndex = () => {
	const { isNotFound, footerHeight, previewIssue, scrollToIssue } = useLocalState(),
    staticData = useStaticQuery(ISSUE_INDEX_QUERY),
    { data, isPreview } = useMergePrismicPreviewData(staticData),
    issues = previewIssue
      ? [previewIssue, ...data.allPrismicIssue.nodes]
      : data.allPrismicIssue.nodes,
    issueIndex = useRef(null),
    [issueIndexHeight, setIssueIndexHeight] = useState(0),
    [clientHeight, setClientHeight] = useState(0),
    translationRequired = issueIndexHeight > clientHeight,
    [translationZero, setTranslationZero] = useState(false),
    totalIssueScroll = Math.max(0, issueIndexHeight - clientHeight),
    setHeights = () => {
      const newClientHeight = document.documentElement.clientHeight,
        newIssueIndexHeight = issueIndex.current
          ? issueIndex.current.offsetHeight
          : 0 
      
      if(newClientHeight !== clientHeight) setClientHeight(newClientHeight)
      if(newIssueIndexHeight !== issueIndexHeight) setIssueIndexHeight(newIssueIndexHeight)
    },
    handleScroll = () => {
      // Short circuit if ref does not exist
      if(!issueIndex.current) return
      // Short circuit if hidden
      if(!showIssueIndex) return
      // Short circuit if no translation is required and translation is already set to zero
      if(!translationRequired) {
        if(!translationZero) {
          issueIndex.current.style.transform = `translate3d(0, 0px, 0)`
          setTranslationZero(true)
        }
        return
      }

      const
        scrollTop = document.scrollingElement.scrollTop,
        scrollDeduction = clientHeight + footerHeight + 192 - 20,
        scrollHeight = document.scrollingElement.scrollHeight - scrollDeduction,
        scrollPercentage = Math.max(0, Math.min(1, scrollTop / scrollHeight)),
        translateY = (-1 * scrollPercentage * totalIssueScroll)

      issueIndex.current.style.transform = `translate3d(0, ${translateY}px, 0)`
      if(translationZero) setTranslationZero(false)
    },
    { pathname } = useLocation(),
    showIssueIndex = (
      pathname.lastIndexOf(`/`) === pathname.length - 1
        ? (pathname.match(/\//g) || []).length <= 2
        : (pathname.match(/\//g) || []).length === 1
     ) || /\/authors\//.test(pathname) || isNotFound,
    linkToIssuePage = pathname !== `/`,
    triggerTransition = useTriggerTransition(),
    handleTransition = (to) => {
      if(linkToIssuePage) {
        triggerTransition({
          to,
          entry: pageTransitionIn,
          exit: pageTransitionOut,
        })
        return true
      }
      return false
    }

  useEventListener(`resize`, setHeights)
  useLayoutEffect(() => {
    handleScroll()
    setHeights()
  })

  useEventListener(
    `scroll`,
    handleScroll,
    undefined,
    { passive: true },
  )

  return (
    <div
      className={`sticky z-40 pointer-events-none top-0 left-0 hidden lg:block p-5 ${showIssueIndex ? `` : `opacity-0`} transition-opacity duration-300`}
      ref={issueIndex}
      style={!translationRequired ? {
        marginTop: `calc(-1 * (var(--issue-index-height) + var(--footer-height) + 192px - 20px))`,
        marginBottom: `calc(var(--footer-height) + 192px - 20px)`,
        willChange: `transform`,
      } : {
        marginTop: `calc(-1 * (var(--issue-index-height) + var(--footer-height) + 192px - var(--issue-total-scroll) - 20px)`,
        marginBottom: `calc(var(--footer-height) + 192px - var(--issue-total-scroll) - 20px)`,
        willChange: `transform`,
      }}
    >
      <style>{`
        :root {
          --issue-index-height: ${issueIndexHeight}px;
          --issue-total-scroll: ${totalIssueScroll}px;
        }`}</style>
      {issues.map((issue, i, a) => {
        const image = getImage(issue?.data?.featured_image)
        return (
          <a
            className={`group block ${i < a.length - 1 ? `mb-4` : ``} no-text-issue`}
            key={issue?.id}
            href={linkResolver(issue)}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              // Transition programmatically when directed
              if(handleTransition(linkResolver(issue))) return

              scrollToIssue(issue?.uid)
            }}
          >
            <div className="relative w-24">
              <GatsbyImage
                alt={issue?.data?.featured_image?.alt ?? ``}
                className={showIssueIndex ? `pointer-events-auto` : `pointer-events-none`}
                image={image}
                sizes="144px"
              />
              <Arch
                className="absolute top-0 left-0 right-0 w-full opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-300 ease-in-out text-white fill-current"
                style={{
                  transform: `translate(-0.5px, -0.5px)`,
                  width: `calc(100% + 1px)`,
                }}
              />
            </div>
            <h5 className={`${showIssueIndex ? `pointer-events-auto` : `pointer-events-none`} inline-block mt-1.5 uppercase text-xs`}>
              Issue <span className="normal-case">No.</span> {issue?.data?.issue_number?.text}{' '}
              <span className="font-serif text-match normal-case italic">
                {issue.data?.issue_title?.text}
              </span>
            </h5>
          </a>
        )
      })}
    </div>
  )
}

export default IssueIndex
export { IssueIndex }