// const linkResolver = doc => {
//     // Pretty URLs for known types
//     if (doc.type === 'blog') return `/post/${doc.uid}`
//     if (doc.type === 'page') return `/${doc.uid}`
//     // Fallback for other types, in case new custom types get created
//     return `/${doc.id}`
// }

const slugify = require('slugify')

const slug = (path) => {
  return typeof path === `string`
    ? slugify(path, {
        lower: true,
        remove: /[*+~.()'"!:;@]/g,
      })
    : path
}

const pathDictionary = {
  issue: `issues`,
  letter: `issues`,
  interview: `interviews`,
  essay: `essays`,
  photo_story: `photo-stories`,
  author: `authors`,
  poem: `poems`,
}

const linkResolver = (doc) => {
  if(!doc) return `/`

  if (Object.keys(pathDictionary).includes(doc.type)) {
    return `/${pathDictionary[doc.type]}/${slug(doc.uid)}`
  }

  if(doc.type === `page`) return `/${slug(doc.uid)}`

  return `/`
}

module.exports = linkResolver
