// scrollIntoView({ element }).then(() => {
  // Do stuff
// })

// scrollIntoView({ top }).then(() => {
  // Do stuff
// })

const scrollIntoView = ({ deviation = 10, element, top = 0, forfeit = 10000 }) => {
  return new Promise((resolve, reject) => {
    let target, timeout
    const elementOffsetTop = element
      ? element.offsetTop
      : 0,
    scrollListener = event => {
      if (typeof event === `undefined`) return

      target = typeof event.target === `undefined` || event.target === document
        ? document.scrollingElement
        : event.target

      if (Math.abs(target.scrollTop - (elementOffsetTop + top)) < deviation) {
        window.removeEventListener(`scroll`, scrollListener)
        clearTimeout(timeout)
        resolve(`Scroll complete`)
      }
    }

    timeout = window.setTimeout(() => {
      window.removeEventListener(`scroll`, scrollListener)
      reject(`Failed to complete scroll within forfeit duration (${forfeit}ms)`)
    }, forfeit)

    window.addEventListener(`scroll`, scrollListener)
    window.scroll({
      top: elementOffsetTop + top,
      left: 0,
      behavior: `smooth`,
    })
  })
}

export default scrollIntoView
export { scrollIntoView }