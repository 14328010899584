import React from 'react'
import { useCookie } from '../hooks/useCookie'
import { usePortal } from '../hooks/usePortal'

const Sidebar = ({
  children,
  options = {
    path: `/`,
    expires: 0,
  },
  openProp = true,
  id,
}) => {
  const [openState, setOpenState] = useCookie(id, true, options),
    close = (override, callback) => {
      if (openState) {
        setOpenState(false, !override?.type && override)
        if (callback) callback()
      }
    },
    sidebar = (
      <div
        className={`sidebar-container p-6 sm:p-5 pt-0 w-full sm:w-3/4 md:w-1/2 xl:w-1/4 ${
          openProp && openState ? `open pointer-events-auto` : `closed pointer-events-none opacity-0`
        } transition-opacity duration-300`}
      >
        <div
          className={`sidebar relative p-6 pr-12 bg-green rounded-2xl shadow text-white `}
        >
          <button
            className="absolute top-0 right-0 leading-0"
            style={{ fontSize: `1.35rem`, width: `34px`, height: `34px` }}
            onClick={close}
          >
            &times;
          </button>
          {typeof children === `function` ? children(close) : children}
        </div>
      </div>
    )

  return (
    usePortal(sidebar, {
      className: `fixed z-30 pointer-events-none inset-0 flex flex-col items-end justify-end`,
    }) || null
  )
}

export default Sidebar
export { Sidebar }
